// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-portfolio-art-js": () => import("./../../../src/pages/portfolio/art.js" /* webpackChunkName: "component---src-pages-portfolio-art-js" */),
  "component---src-pages-portfolio-design-thinking-js": () => import("./../../../src/pages/portfolio/design-thinking.js" /* webpackChunkName: "component---src-pages-portfolio-design-thinking-js" */),
  "component---src-pages-portfolio-hackathon-js": () => import("./../../../src/pages/portfolio/hackathon.js" /* webpackChunkName: "component---src-pages-portfolio-hackathon-js" */),
  "component---src-pages-portfolio-kfitzah-js": () => import("./../../../src/pages/portfolio/kfitzah.js" /* webpackChunkName: "component---src-pages-portfolio-kfitzah-js" */),
  "component---src-pages-portfolio-lemonade-js": () => import("./../../../src/pages/portfolio/lemonade.js" /* webpackChunkName: "component---src-pages-portfolio-lemonade-js" */),
  "component---src-pages-portfolio-what-the-tech-js": () => import("./../../../src/pages/portfolio/what-the-tech.js" /* webpackChunkName: "component---src-pages-portfolio-what-the-tech-js" */),
  "component---src-pages-press-js": () => import("./../../../src/pages/press.js" /* webpackChunkName: "component---src-pages-press-js" */)
}

